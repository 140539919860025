import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 状态管理
// import {createPinia} from 'pinia'

// element
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// 
// // 将echarts 挂载到Vue3原型
import * as echarts from 'echarts'

//rem
import './../public/rem.js'

// Cookies
// import Cookies from 'js-cookie'
// import {
//     ElMessage
// } from 'element-plus'
createApp(App).use(store).use(ElementPlus, {
    locale: zhCn,
}).use(echarts).use(router).mount('#app')

/**
 * 全局前置路由守卫，每一次路由跳转前都进入这个 beforeEach 函数
 */
// router.beforeEach((to, from, next) => {
//     console.log(to, from, next);
//     const token = localStorage.getItem('autotoken');
//     console.log(token, 'token', Cookies.get('JSESSIONID'));
//     return
//     if (to.path == '/login') {
//         // 登录或者注册才可以往下进行
//         next();
//     } else {
//         // 获取 token
//         const token = localStorage.getItem('autotoken');
//         console.log(token, 'token', Cookies.get('JSESSIONID'));
//         // token 不存在
//         if (!token) {
//             ElMessage.error('您还没有登录，请先登录');
//             next('/login');
//         } else {
//             next();
//         }
//     }
// });