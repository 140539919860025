import {
  createStore
} from 'vuex'
import {
  mapinfo as mapinfo
} from './map/mapinfo.js'

export default createStore({
  state: {
    mapseach: null, //搜索地理位置input
    mapcut: true, //搜索地理列表list
  },
  getters: {},
  mutations: {
    editmapseach(state, data) {
      state.mapseach = data
    },
    editmapcut(state, status) {
      state.mapcut = status
    }
  },
  actions: {},
  modules: {
    mapinfo,
  }
})