const mapinfo = {
    namespaced: true, //开启命名空间
    state: {
        // 四级体系和旧改进度弹框
        lfdialog: 0,
        // 地块地标弹框
        lfmap: null, //坐标
        lfmapname: '首页概览', //名称
        lfmapid: null, //显示id
        mapmsg: null, //地图信息
        rggroundid: null, //地块id
        publicPop: null, //公共弹窗
        ecomomyPop: null, //经济专题弹窗
        jingjiData: null, //经济专题左侧传值

        mappeo: false, //居住人口弹窗
        laninfodo: false, //地块信息弹窗
        illinfodo: false, //疫情详情开关
        illinfo: null, //疫情详情
        bigques: null, //旧改问题大类街道name
    },
    mutations: {
        emitrggroundid(state, num) {
            state.rggroundid = num
        },
        emitlfdialog(state, num) {
            state.lfdialog = num
        },
        emitlfmap(state, numx) {
            state.lfmap = numx
        },
        emitlefmapname(state, mapname) {
            state.lfmapname = mapname
        },
        emitlfmapid(state, mapid) {
            state.lfmapid = mapid
        },
        emitmapMsgde(state, mapmsg) {
            state.mapmsg = mapmsg
        },
        emitpublicPop(state, publicPop) {
            state.publicPop = publicPop
        },
        emitecomomyPop(state, comomyPop) {
            state.comomyPop = comomyPop
        },
        emitejingjiData(state, jingjiData) {
            state.jingjiData = jingjiData
        },
        // 
        emitmappeo(state, status) {
            state.mappeo = status
        },
        emitlaninfodo(state, status) {
            state.laninfodo = status
        },
        editillinfodo(state, status) {
            state.illinfodo = status
        },
        editillinfo(state, data) {
            state.illinfo = data
        },
        editbigques(state, name) {
            state.bigques = name
        },

    },
    actions: {},
    getters: {},
}
export {
    mapinfo
}