import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [
  // 重定向
  {
    path: '/',
    redirect: 'login'
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  // 首页
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue'),
    children: [
      // home
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/home.vue'),
      },
      // 台账
      {
        path: '/backmana',
        name: 'backmana',
        component: () => import('../views/backmana/backmana.vue')
      },
    ]
  },

  // 地图
  {
    path: '/maps',
    name: 'maps',
    component: () => import('../views/map/index.vue'),
    meta: {
      keepAlive: true,
      showFooter: true,
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router